type Tenant = {
  hideVersion?: boolean
  hideAdminNavLinks?: boolean
  hideRialticOnlyViews: boolean
  supportCognitoAuth: boolean
  singleTenantWorkspaceId: string
}

export default defineAppConfig({
  tenant: {
    hideVersion: false,
    hideAdminNavLinks: false,
    hideRialticOnlyViews: false,
    supportCognitoAuth: true,
    singleTenantWorkspaceId: '',
  } as Tenant,
  host: '',
  version: '',
})
