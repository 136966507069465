import routerOptions0 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.14.1592_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.1_rpwwkqm37tpukuth6nr3bd5yni/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/rialtic/workflows/console-ui/apps/console/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}